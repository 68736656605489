import * as React from "react"
import Layout from '../components/Layout'
import Hero from "../components/Hero"
import { graphql } from 'gatsby'
import { returnTitleSubHeadingsAndImages } from '../utilities/helperFunctions'
import SectionTitleComponent from "../components/SectionTitleComponent"
import LocationCards from '../components/About/Locations'
import ContactForm from '../components/Contact/Form'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        paddingBottom: "30px",
        [theme.breakpoints.up('sm')]: {
            paddingBottom: "3rem"
        }
    }
}))

const IndexPage = ({ data }) => {
    const classes = useStyles()
    const contactPageContent = data.cms.brsContactContent
    const heroData = returnTitleSubHeadingsAndImages(contactPageContent)
    const companyLocations = !!data.cms.brsCompanyLocations ? data.cms.brsCompanyLocations : []
    const SEOData = !!contactPageContent.Seo ? contactPageContent.Seo : {}
    return (
        <div>
            <Layout showContact={false} pageSeo={SEOData}>
                <Hero
                    title={heroData.title}
                    subHeadings={heroData.subHeadings}
                    coverImageMobile={heroData.coverImageMobile}
                    coverImageWeb={heroData.coverImageWeb}
                    showButton={false}
                />
                <div className={classes.container}>
                    <ContactForm />
                    {
                        companyLocations.length !== 0 &&
                        <SectionTitleComponent
                            sectionId='locations'
                            title="Our"
                            coloredTitle='Company Locations'

                        >
                            <LocationCards cardList={companyLocations} />
                        </SectionTitleComponent>
                    }
                </div>
            </Layout>
        </div>
    )
}

export const query = graphql`
query ContactPageQuery {
  cms {
    brsContactContent {
        id
        heading
        subHeading
        subheading2
        coverImageWeb {
          url
          alternativeText
        }
        coverImageMobile {
          url
          alternativeText
        }
        Seo {
          SCHEMA_TAG
          SEO_TITLE
          SEO_DESCRIPTION
        }
      }
      brsCompanyLocations {
        address
        contactEmail
        contactPhone1
        contactPhone2
        googleEmbedURL
        id
        location
      }
  }
}
`

export default IndexPage
